<template>
   <v-container>
      <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit" />
      <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
      </v-skeleton-loader>
      <v-data-table v-if="!api.isLoading" :search="search" :items="data" :headers="dataHeader" class="elevation-1">
         <!-- <template
               v-slot:item.index="{item}">
               {{ ((options.page-1) * options.itemsPerPage) + (data.data.indexOf(item)+1) }}
           </template> -->
         <!-- <template
               v-slot:expanded-item="{headers,item}">
               <td
                   :colspan="headers.length">
                   <v-row
                       class="ma-2 pa-2">
                       <v-col
                           cols="12" sm="3">
                           <div
                               class="d-flex justify-start">
                               <span
                                   class="text-h6">
                                   Roles
                               </span>
                           </div>
                           <v-col
                               class="px-0 mt-2">
                               {{ "1 ) "+item.role.charAt(0).toUpperCase()+item.role.slice(1)+" ( Masterclass )"  }}
                           </v-col>
                           <v-col
                               class="mt-3"
                               v-if="Object.entries(item.roles).length!=0">
                               <v-row
                                   v-for="(item,index) in item.roles"
                                   :key="index">
                                   <div
                                       class="d-flex justify-start mb-2">
                                       {{ 1+index+1+" ) "+item.name.charAt(0).toUpperCase()+item.name.slice(1).replaceAll("_"," ") }} {{ AAERole.includes(item.name)?" ( Ask An Expert ) ":"" }} {{ VisionRole.includes(item.name)?" ( Vision )":"" }}
                                   </div>
                               </v-row>
                           </v-col>
                           <v-col
                               class="mt-3"
                               v-else>
                               No access to AAE and Vision
                           </v-col>
                       </v-col>
                   </v-row>
               </td>   
           </template> -->
         <!-- <template
               v-slot:item.user_name="{item}">
               <router-link
                   :to="{name:'PageStaffDetail',params:{id:item.user_id}}">
                       {{ item.user_name }} 
               </router-link>
           </template> -->
         <template v-slot:item.index="{ item }">
            {{ data.indexOf(item) + 1 }}
         </template>
         <template v-slot:top>
            <v-toolbar flat class="text-h4 pt-4" height="auto">
               <v-row no-gutters>
                  <v-col class="mt-3 mx-3">
                     <v-row class="mb-3">
                        <v-toolbar-title class="font-weight-bold text-h4 my-3">
                           Account Type List (Excel)
                        </v-toolbar-title>
                        <v-spacer>
                        </v-spacer>
                        <v-btn color="primary" @click="fetch()" class="mt-3">
                           Refresh
                        </v-btn>
                        <JsonCSV :data="data" class="mx-2 mt-2">
                           <v-btn color="primary">
                              Download
                           </v-btn>
                        </JsonCSV>
                     </v-row>
                     <v-row>
                        <v-text-field v-model="search" dense outlined label="Search">
                        </v-text-field>
                     </v-row>
                  </v-col>
               </v-row>
            </v-toolbar>
         </template>
         <template v-slot:item.bonus_offer="{ item }">
            <span v-if="item.bonus_offer != NULL">
               {{ item.bonus_offer }}
            </span>
            <span v-else>
               -
            </span>
         </template>
         <template v-slot:item.created_at="{ item }">
            {{ convertTimeZone(item.created_at) }}
         </template>
      </v-data-table>
   </v-container>
</template>
 
<script>
import { mapState } from 'vuex';

export default {
   components: {
      //
   },
   computed: mapState({
      //
   }),
   props: [
      //
   ],
   data: () => ({
      expanded: [],
      search: "",
      data: null,
      dataHeader: [
         {
            text: '#',
            value: 'index',
         },
         {
            text: 'Company',
            value: 'company',
         },
         {
            text: 'Username',
            value: 'username',
         },
         {
            text: 'Email',
            value: 'email',
         },
         {
            text: 'Account Type',
            value: 'acc_type',
         },
         {
            text: 'Bonus Offer',
            value: 'bonus_offer'
         },
      ],
      // AAERole: [
      //    'client',
      //    'consultant',
      //    'tax_expert',
      //    'admin',
      //    'manager',
      // ],
      // VisionRole: [
      //    'salesperson',
      //    'customer_service',
      //    'sales_team_lead',
      //    'vision_admin',
      //    'operation',
      //    'sales_manager',
      //    'developer',
      //    'marketing',
      // ],
      options: {
         itemsPerPage: 20,
         page: 1,
         sortBy: [],
         search: '',
         couponSearch: '',
      },
      isFirstLoading: true,
      isPending: false,
      api:
      {
         isLoading: false,
         isError: false,
         error: null,
         url: null,
         isSuccesful: false,
         success: null,
      },
   }),
   created() {
      this.api.callbackReset = () => {
         this.api.isLoading = true;
         this.api.isError = false;
      };
      this.api.callbackError = (e) => {
         this.api.isLoading = false;
         this.api.isError = true;
         this.api.error = e;
      };
      this.api.callbackSuccess = (resp) => {
         if (resp.class === "getAccountTypeExcelList") {
            this.data = resp.data;
         }
         this.api.isLoading = false;
         this.api.isError = false;
      };
   },
   mounted() {
      this.fetch();
   },
   methods: {
      fetch() {
         let fetchStaffListApi = this.fetchStaffList();
         this.$api.fetch(fetchStaffListApi);
      },
      fetchStaffList() {
         let tempApi = this.$_.clone(this.api);
         tempApi.method = "GET";
         tempApi.url = process.env.VUE_APP_SERVER_API + "/developer/excel/account_type";
         return tempApi;
      },
      validateInput() {
         this.isPending = true;
      },
      cancelSubmit() {
         this.isPending = false;
      },
      submit() {
         this.isPending = false;
         let reApplyCouponApi = this.reApplyCoupon();
         this.$api.fetch(reApplyCouponApi);
      },
      convertTimeZone(time) {
         return this.$moment(time).format('LLL');
      },
   }
}
</script>