<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit" />
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <ASuccessWithoutReload :api="this.api" @close="handleClose" />
        <v-data-table v-if="!api.isLoading" :search="search" :items="data" :headers="dataHeader" class="elevation-1">
            <template v-slot:item.index="{ item }">
                {{ data.indexOf(item) + 1 }}
            </template>
            <template v-slot:top>
                <v-toolbar flat class="text-h4 pt-4" height="auto">
                    <v-row no-gutters>
                        <v-col class="mt-3 mx-3">
                            <v-row class="mb-3">
                                <v-toolbar-title class="font-weight-bold text-h4 my-3">
                                    Account Type List Email Matched
                                </v-toolbar-title>
                                <v-spacer>
                                </v-spacer>
                                <v-btn color="primary" @click="fetch()" class="mx-2 mt-3">
                                    Refresh
                                </v-btn>
                                <v-btn :loading="api.isLoading" class="mt-3" color="primary" @click="transfer()">
                                    Transfer
                                </v-btn>
                                <JsonCSV :data="data" class="mx-2 mt-2">
                                    <v-btn color="primary">
                                        Download
                                    </v-btn>
                                </JsonCSV>
                            </v-row>
                            <v-row>
                                <v-text-field v-model="search" dense outlined label="Search">
                                </v-text-field>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-toolbar>
            </template>
            <template v-slot:item.bonus_offer="{ item }">
                <span v-if="item.bonus_offer != NULL">
                    {{ item.bonus_offer }}
                </span>
                <span v-else>
                    -
                </span>
            </template>
        </v-data-table>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex';
import ASuccessWithoutReload from '../../common/ASuccessWithoutReload.vue';

export default {
    components: {
        ASuccessWithoutReload,
    },
    computed: mapState({
        //
    }),
    props: [
        //
    ],
    data: () => ({
        expanded: [],
        search: "",
        data: null,
        dataHeader: [
            {
                text: '#',
                value: 'index',
            },
            {
                text: 'Current Company ID',
                value: 'current_company',
            },
            {
                text: 'Username Database',
                value: 'username',
            },
            {
                text: 'Username Excel',
                value: 'excel_username',
            },
            {
                text: 'Email Database',
                value: 'user_email',
            },
            {
                text: 'Email Excel',
                value: 'excel_email',
            },
            {
                text: 'Account Type Excel',
                value: 'acc_type',
            },
            {
                text: 'Bonus Offer Excel',
                value: 'bonus_offer',
            },
        ],
        // AAERole: [
        //     'client',
        //     'consultant',
        //     'tax_expert',
        //     'admin',
        //     'manager',
        // ],
        // VisionRole: [
        //     'salesperson',
        //     'customer_service',
        //     'sales_team_lead',
        //     'vision_admin',
        //     'operation',
        //     'sales_manager',
        //     'developer',
        //     'marketing',
        // ],
        options: {
            itemsPerPage: 20,
            page: 1,
            sortBy: [],
            search: '',
            couponSearch: '',
        },
        isFirstLoading: true,
        isPending: false,
        api:
        {
            isLoading: false,
            isError: false,
            error: null,
            url: null,
            isSuccesful: false,
            success: null,
        },
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if (resp.class === "fixAccountTypeUsingExcel") {
                this.api.isSuccesful = true;
                this.api.success = "Succesfully fix the account type for users";
            }
            if (resp.class === "getAccountTypeListMatched") {
                this.data = resp.data;
            }
            this.api.isLoading = false;
            this.api.isError = false;
        };
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            let fetchStaffListApi = this.fetchStaffList();
            this.$api.fetch(fetchStaffListApi);
        },
        fetchStaffList() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/developer/excel/account_type/matched";
            return tempApi;
        },
        validateInput() {
            this.isPending = true;
        },
        cancelSubmit() {
            this.isPending = false;
        },
        transfer() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/developer/excel/account_type/fix/" + this.$store.getters.getUserId;
            this.$api.fetch(tempApi);
        },
        handleClose() {
            this.fetch();
            this.api.isSuccesful = false;
        },
        submit() {
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        convertTimeZone(time) {
            return this.$moment(time).format('LLL');
        },
    },
}
</script>