<!-- <template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit" />
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <ComponentDeveloperUploadStaffList />
        <ComponentStaffListExcel />
        <v-row class="ma-2">
            <span style="font-size:20px">
                Below is the new component for the staff list
            </span>
        </v-row>
        <ComponentStaffListv2 />
        <ComponentStaffListMatched />
        <ComponentStaffListUnmatched />
    </v-container>
</template>

<script>
import { mapState } from 'vuex'
import ComponentStaffListExcel from '../../components/staff/ComponentStaffListExcel.vue';
import ComponentDeveloperUploadStaffList from '../../components/developer/clean/ComponentDeveloperUploadStaffList.vue';
import ComponentStaffListv2 from '../../components/staff/ComponentStaffListv2.vue';
import ComponentStaffListUnmatched from '../../components/staff/ComponentStaffListUnmatched.vue';
import ComponentStaffListMatched from '../../components/staff/ComponentStaffListMatched.vue';

export default {
    components: {
        ComponentDeveloperUploadStaffList,
        ComponentStaffListv2,
        ComponentStaffListExcel,
        ComponentStaffListUnmatched,
        ComponentStaffListMatched,
    },
    computed: mapState({
        //
    }),
    props: [
        //
    ],
    data: () => ({
        data: null,
        dataHeader: [
        ],
        isPending: false,
        api:
        {
            isLoading: false,
            isError: false,
            error: null,
            url: null,
            isSuccesful: false,
            success: null,
        }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = () => {
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
    },
    methods: {

        validateInput() {
            this.isPending = true;
        },
        cancelSubmit() {
            this.isPending = false;
        },
        submit() {
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
    }
}
</script> -->

<template>
   <v-container>
      <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit" />
      <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
      </v-skeleton-loader>
      <ComponentDeveloperUploadAccountType />
      <ComponentDeveloperAccountTypeExcelList />
      <ComponentDeveloperAccountTypeDatabaseList />
      <ComponentDeveloperBonusOfferUnmatched />
      <v-row class="ma-2">
         <span style="font-size:20px">
            Below is the Matched and Unmatched Account Type List
         </span>
      </v-row>
      <ComponentDeveloperAccountTypeMatched />
      <ComponentDeveloperAccountTypeUnmatched />
   </v-container>
</template>

<script>
import { mapState } from 'vuex';
import ComponentDeveloperUploadAccountType from '../../components/developer/clean/ComponentDeveloperUploadAccountType.vue';
import ComponentDeveloperAccountTypeExcelList from '../../components/developer/clean/ComponentDeveloperAccountTypeExcelList.vue';
import ComponentDeveloperAccountTypeDatabaseList from '../../components/developer/clean/ComponentDeveloperAccountTypeDatabaseList.vue';
import ComponentDeveloperBonusOfferUnmatched from '../../components/developer/clean/ComponentDeveloperBonusOfferUnmatched.vue';
import ComponentDeveloperAccountTypeMatched from '../../components/developer/clean/ComponentDeveloperAccountTypeMatched.vue';
import ComponentDeveloperAccountTypeUnmatched from '../../components/developer/clean/ComponentDeveloperAccountTypeUnmatched.vue';

export default {
   components: {
      ComponentDeveloperUploadAccountType,
      ComponentDeveloperAccountTypeExcelList,
      ComponentDeveloperAccountTypeDatabaseList,
      ComponentDeveloperBonusOfferUnmatched,
      ComponentDeveloperAccountTypeMatched,
      ComponentDeveloperAccountTypeUnmatched,
   },
   computed: mapState({
      //
   }),
   props: [
      //
   ],
   data: () => ({
      data: null,
      dataHeader: [
      ],
      isPending: false,
      api:
      {
         isLoading: false,
         isError: false,
         error: null,
         url: null,
         isSuccesful: false,
         success: null,
      },
   }),
   created() {
      this.api.callbackReset = () => {
         this.api.isLoading = true;
         this.api.isError = false;
      };
      this.api.callbackError = (e) => {
         this.api.isLoading = false;
         this.api.isError = true;
         this.api.error = e;
      };
      this.api.callbackSuccess = () => {
         this.api.isLoading = false;
         this.api.isError = false;
      };
   },
   mounted() {
      this.fetch();
   },
   methods: {
      fetch() {
         let fetchFailedCouponApi = this.fetchFailedCoupon();
         this.$api.fetch(fetchFailedCouponApi);
      },
      validateInput() {
         this.isPending = true;
      },
      cancelSubmit() {
         this.isPending = false;
      },
      submit() {
         this.isPending = false;
         let reApplyCouponApi = this.reApplyCoupon();
         this.$api.fetch(reApplyCouponApi);
      },
   }
}
</script>