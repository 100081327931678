<template>
   <v-container>
      <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit" />
      <ASuccessWithoutReload :api="this.api" @close="closeModal" />
      <v-row class="mt-3">
         <v-col align="center" justify="center" cols="12" sm="8" class="mx-auto">
            <v-col align="start" justify="start">
               <v-btn @click="redirectBack" class="mb-3" color="primary">
                  <v-icon>
                     mdi-arrow-left-bold
                  </v-icon>
               </v-btn>
            </v-col>
            <v-card class="pa-3" elevation-1>
               <v-toolbar color="secondary" class="white--text mb-4">
                  <v-toolbar-title>
                     Upload Account Type Excel
                  </v-toolbar-title>
               </v-toolbar>
               <div class="my-5">
                  <v-file-input :rules="rules" accept="xlsx" color="primary" v-model="files" outlined prepend-icon=""
                     label="Please choose the files" counter :show-size="1000">
                     <template v-slot:selection="{ index, text }">
                        <v-chip v-if="index < 2" color="deep-purple accent-4" dark label small>
                           {{ text }}
                        </v-chip>
                        <span v-else-if="index == 2" class="text-overline grey--text text--darken-3">
                           +{{ files.length - 2 }} Files(s)
                        </span>
                     </template>
                  </v-file-input>
               </div>
               <div class="pa-2 d-flex justify-end mt-5 mb-3">
                  <v-btn color="primary" @click="validateInput" :loading="api.isLoading">
                     Submit
                  </v-btn>
               </div>
               <div>
                  <AError :api="this.api" />
               </div>
            </v-card>
         </v-col>
      </v-row>
   </v-container>
</template>
  
<script>
import { mapState } from 'vuex';
import AConfirmation from '../../common/AConfirmation.vue';
import ASuccessWithoutReload from '../../common/ASuccessWithoutReload.vue';
import AError from '../../common/AError.vue';

export default {
   components: {
      AConfirmation,
      ASuccessWithoutReload,
      AError,
   },
   computed: mapState({
      //
   }),
   props: [
      //
   ],
   data: () => ({
      isHoverColor: 'grey',
      isPending: false,
      rules: [
         value => value[0].size < 10000000 || 'File size should be less than 10 MB!',
         value => value.length <= 1 || 'Total files that can be uploaded should be less than 3 files',
      ],
      files: [],
      remark: null,
      api: {
         isSuccesful: false,
         isError: false,
         isLoading: false,
         error: null,
         url: null,
         success: null,
      },
      companyId: null,
   }),
   created() {
      this.api.callbackReset = () => {
         this.api.isLoading = true;
         this.api.isError = false;
         this.api.isSuccesful = false;
      };
      this.api.callbackError = (e) => {
         this.api.isError = true;
         this.api.isLoading = false;
         this.api.isSuccesful = false;
         this.api.error = e;
      };
      this.api.callbackSuccess = (resp) => {
         if (resp.class == 'uploadExcelAccountType') {
            this.api.success = "Succesfully upload the file";
            this.api.isSuccesful = true;
         }
         this.api.isError = false;
         this.api.isLoading = false;
      }
   },
   mounted() {
      this.fetch();
   },
   methods: {
      fetch() {
         this.companyId = this.$router.history.current.params.id;
      },
      redirectBack() {
         this.$router.go(-1);
      },
      validateInput() {
         this.isPending = true;
      },
      submit() {
         this.isPending = false;
         let createAttachmentApi;
         let validated = this.validateAttachment();
         if (!validated) {
            return 1;
         }
         createAttachmentApi = this.create();
         this.$api.fetch(createAttachmentApi);
      },
      cancelSubmit() {
         this.isPending = false;
      },
      validateAttachment() {
         if (!this.files || this.files.length === 0) {
            this.api.isError = true;
            this.api.error = "No file selected.";
            console.error(this.api);
            return false;
         }


         const allowedTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "text/csv"];

         if (!allowedTypes.includes(this.files.type)) {
            this.api.isError = true;
            this.api.error = `Invalid file format. Allowed formats are: ${allowedTypes.join(', ')}.`;
            console.error(this.api);
            return false;
         }
         return true;
      },
      create() {
         let tempApi = this.$_.clone(this.api);
         tempApi.methods = "POST";
         tempApi.url = process.env.VUE_APP_SERVER_API + '/developer/excel/account_type/' + this.$store.getters.getUserId;
         let formData = new FormData();
         if (this.files != null) {
            formData.append('attachment', this.files);
         }
         tempApi.params = formData;
         return tempApi;
      },
      closeModal() {
         this.api.isSuccesful = false;
      },
   },
}
</script>